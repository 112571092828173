import { css } from 'linaria'
import React, { memo } from 'react'
import Title from '../common/Title'
import Anchor from '../common/Anchor'
import { breakPoints, colors, fontSizes } from '../../constants'

const Member: React.FC = () => (
  <section className={container}>
    <Title className={title} lineColor="brand">
      MEMBER
    </Title>
    <div className={flexbox}>
      <ul className={memberList}>
        <li className={member}>
          <h2>Artwork</h2>
          <Anchor href="https://twitter.com/ochokonoflower" external>
            hanaken
          </Anchor>
        </li>
        <li className={member}>
          <h2>Vocal</h2>
          <Anchor href="https://twitter.com/Juicy_ringo" external>
            月島春果
          </Anchor>
        </li>
        <li className={member}>
          <h2>Design</h2>
          <ul>
            <li>
              <Anchor href="https://twitter.com/eins_blue" external>
                eins [eins.blue]
              </Anchor>
            </li>
            <li>
              <Anchor href="http://bit.ly/mya-lab" external>
                mya-lab.
              </Anchor>
            </li>
          </ul>
        </li>
        <li className={member}>
          <h2>Remix</h2>
          <ul>
            <li>
              <Anchor href="https://twitter.com/ygt_jpn" external>
                yuigot
              </Anchor>
            </li>
            <li>
              <Anchor href="https://twitter.com/KBSNK_TEMPLIME" external>
                KBSNK (TEMPLIME)
              </Anchor>
            </li>
            <li>
              <Anchor href="https://twitter.com/yuasahina" external>
                Yu_Asahina
              </Anchor>
            </li>
          </ul>
        </li>
        <li className={member}>
          <h2>Artwork Support (3D Modeling)</h2>
          <Anchor href="https://twitter.com/Junkfood_CoLeo" external>
            CoLeo
          </Anchor>
        </li>
        <li className={member}>
          <h2>Web Design</h2>
          <Anchor href="https://twitter.com/saitolume" external>
            saitolume
          </Anchor>
        </li>
        <li className={member}>
          <h2>All songs written by</h2>
          <Anchor href="https://twitter.com/kamomesano" external>
            さのかもめ
          </Anchor>
        </li>
      </ul>
    </div>
  </section>
)

const container = css`
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
  text-align: center;
`

const title = css`
  position: absolute;
  top: -40px;

  @media (max-width: ${breakPoints.tablet}) {
    top: 0;
  }
`

const flexbox = css`
  width: calc(100% - 128px);
  max-width: 1400px;
  height: 100%;
  background-color: ${colors.background2};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 5vw;
  padding: 96px 64px 64px;

  @media (max-width: ${breakPoints.sp}) {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    padding: 32px 24px 64px;
  }
`

const memberList = css`
  width: 100%;
  list-style: none;

  li {
    line-height: 48px;
  }

  @media (max-width: ${breakPoints.sp}) {
    padding: 0;
  }
`

const member = css`
  width: 80%;
  display: inline-flex;
  font-size: 20px;
  margin: 0;
  text-align: left;

  h2 {
    width: 50%;
    min-width: 50%;
    font-size: ${fontSizes.normal};
    font-weight: bold;
    margin: 0;
  }

  dt {
    min-width: 50%;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    li {
      margin-right: 32px;
      white-space: nowrap;
    }
  }

  @media (max-width: ${breakPoints.sp}) {
    width: 100%;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 12px;

    h2 {
      font-size: ${fontSizes.small};
      line-height: 32px;
      white-space: nowrap;
    }
  }
`

export default memo(Member)
