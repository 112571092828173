import React, { memo } from 'react'
import { css } from 'linaria'
import Image from '../common/Image'
import { breakPoints, colors, fontSizes } from '../../constants'
import Anchor from '../common/Anchor'

const scrollToSection = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.preventDefault()
  const { hash } = new URL(event.currentTarget.href)
  const section = document.querySelector(hash)

  if (section) {
    section.scrollIntoView({
      behavior: 'smooth',
    })
  }
}

const Hero: React.FC = () => (
  <section className={container}>
    <div className={flexbox}>
      <div className={column}>
        <div>
          <Image className={logo} src="logo.png" alt="Limonène" />
          <h1 className={title}>
            <ruby>
              Sour Salty Realism
              <rt>サワーソルティーリアリズム</rt>
            </ruby>
          </h1>
          <p className={datebox}>
            <time dateTime="2020-10-25">2020.10.25</time>release
          </p>
        </div>
        <nav className={nav}>
          <Anchor href="/#info" onClick={scrollToSection}>
            MORE INFO
          </Anchor>
          <Anchor href="/#cd" onClick={scrollToSection}>
            BUY / LISTEN
          </Anchor>
        </nav>
      </div>
      <div className={column}>
        <Image className={artwork} src="artworks/sour-salty-realism.jpg" alt="アートワーク" />
      </div>
    </div>
  </section>
)

const container = css`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`

const flexbox = css`
  width: calc(100% - 128px);
  height: calc(100% - 128px);
  border: 8px solid ${colors.brand};
  display: flex;
  justify-content: center;

  @media (min-width: ${breakPoints.sp}) and (max-width: ${breakPoints.tablet}) {
    width: calc(100% - 64px);
    height: calc(100% - 64px);
  }

  @media (max-width: ${breakPoints.pc}) {
    box-sizing: border-box;
    flex-direction: column;
    padding: 48px;
  }

  @media (max-width: ${breakPoints.sp}) {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    padding: 24px;
  }
`

const column = css`
  height: 100%;
  width: 40%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &:first-child {
    margin-right: 5vw;
  }

  @media (max-width: ${breakPoints.pc}) {
    width: 100%;
  }
`

const logo = css`
  width: 200px;
  height: auto;
  margin: auto;

  @media (max-width: ${breakPoints.sp}) {
    width: 160px;
  }
`

const title = css`
  font-size: ${fontSizes.large};

  @media (max-width: ${breakPoints.tablet}) {
    ruby {
      font-size: 48px;
    }
  }

  @media (max-width: ${breakPoints.sp}) {
    margin: 16px 0 32px;

    ruby {
      font-size: ${fontSizes.midium};

      rt {
        display: none;
      }
    }
  }

  ruby {
    ruby-position: under;
    white-space: nowrap;

    rt {
      font-family: 'a-otf-gothic-bbb-pr6n', sans-serif;
      font-size: ${fontSizes.small};
      transform: translateY(12px);
    }
  }
`

const datebox = css`
  font-size: ${fontSizes.normal};
  margin: 0 auto;

  @media (max-width: ${breakPoints.sp}) {
    margin-bottom: 16px;
  }

  time {
    margin-right: 8px;
  }
`

const nav = css`
  display: flex;
  margin-top: 100px;

  @media (min-width: ${breakPoints.sp}) and (max-width: ${breakPoints.pc}) {
    margin-top: 32px;
  }

  @media (max-width: ${breakPoints.sp}) {
    margin-top: 0px;
    margin-bottom: 16px;
  }

  a {
    font-size: ${fontSizes.normal};
    line-height: 36px;

    &:first-child {
      margin-right: 64px;
    }

    @media (max-width: ${breakPoints.sp}) {
      flex-direction: column;
      font-size: ${fontSizes.small};

      &:first-child {
        margin-right: 32px;
      }
    }
  }
`

const artwork = css`
  min-width: 30vw;
  width: 30vw;
  height: 30vw;

  @media (min-width: ${breakPoints.tablet}) and (max-width: ${breakPoints.pc}) {
    min-width: 35vh;
    width: 35vh;
    height: 35vh;
  }

  @media (min-width: ${breakPoints.sp}) and (max-width: ${breakPoints.tablet}) {
    min-width: 75%;
    height: auto;
  }

  @media (max-width: ${breakPoints.sp}) {
    min-width: 90%;
    width: 90%;
    height: auto;
  }
`

export default memo(Hero)
