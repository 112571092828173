import React, { memo } from 'react'
import { css, cx } from 'linaria'
import Player from '../common/Player'
import Title from '../common/Title'
import { breakPoints, colors, fontSizes } from '../../constants'

const Info: React.FC = () => (
  <section className={container}>
    <Title id="info" className={title} lineColor="limonene">
      INFO
    </Title>
    <div className={flexbox}>
      <section className={cx(column, youtubeBox)}>
        <Player
          className={youtube}
          title="クロスフェード"
          src="https://www.youtube.com/embed/vB4GqZHuhVs"
        />
        <Player
          className={youtube}
          title="リリックビデオ"
          src="https://www.youtube.com/embed/awgZqSdJpB8"
        />
      </section>
      <section className={column}>
        <div className={heading}>
          <p>
            <ruby>
              Limonène
              <rt>リモネン</rt>
            </ruby>
          </p>
          <span className={hyphen}>-</span>
          <p>Sour Salty Realism</p>
        </div>
        <ul className={artistList}>
          <li>
            <dl className={artist}>
              <dt>月島春香</dt>
              <dd>(vocals)</dd>
            </dl>
          </li>
          <li>
            <dl className={artist}>
              <dt>サノカモメ</dt>
              <dd>(programmming and instruments)</dd>
            </dl>
          </li>
        </ul>
        <ol className={trackList}>
          <li>realism</li>
          <li>君の亡者</li>
          <li>perfect blue</li>
          <li style={{ marginBottom: '32px' }}>たわごとのうた</li>
          <li>evergreen pt.1 (kamome sano Remix)</li>
          <li>evergreen pt.2 (kamome sano Remix)</li>
          <li>なんでもないこと (yuigot Remix)</li>
          <li>線香花火 (KBSNK Remix)</li>
          <li>(please) replay, again / Yu_Asahina</li>
        </ol>
      </section>
    </div>
  </section>
)

const container = css`
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
  text-align: center;
`

const title = css`
  position: absolute;
  top: -40px;

  @media (max-width: ${breakPoints.tablet}) {
    top: 0;
  }
`

const flexbox = css`
  width: calc(100% - 128px);
  max-width: 1400px;
  height: 100%;
  background-color: ${colors.background2};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 128px 64px 64px;

  @media (min-width: ${breakPoints.sp}) and (max-width: ${breakPoints.tablet}) {
    width: calc(100% - 64px);
    height: calc(100% - 64px);
  }

  @media (min-width: ${breakPoints.tablet}) and (max-width: ${breakPoints.pc}) {
    flex-direction: column;
  }

  @media (max-width: ${breakPoints.tablet}) {
    box-sizing: border-box;
    flex-direction: column;
  }

  @media (max-width: ${breakPoints.sp}) {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    padding: 80px 24px 64px;
  }
`

const column = css`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  &:first-child {
    margin-right: 5vw;
  }

  @media (min-width: ${breakPoints.tablet}) and (max-width: ${breakPoints.pc}) {
    flex-direction: column;
    &:first-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${breakPoints.tablet}) {
    width: 100%;
    height: auto;
  }
`

const youtubeBox = css`
  @media (min-width: ${breakPoints.tablet}) and (max-width: ${breakPoints.pc}) {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 64px;
  }

  @media (max-width: ${breakPoints.sp}) {
    margin-bottom: 32px;
  }
`

const youtube = css`
  &:first-child {
    margin-bottom: 64px;
  }

  @media (max-width: ${breakPoints.pc}) {
    &:first-child {
      margin-bottom: 32px;
    }
  }

  @media (min-width: ${breakPoints.tablet}) and (max-width: ${breakPoints.pc}) {
    width: 360px;
    height: 202.5px;
  }

  @media (min-width: ${breakPoints.sp}) and (max-width: ${breakPoints.tablet}) {
    width: 100%;
    height: calc(calc(100vw - 128px) / 2);
  }

  @media (max-width: ${breakPoints.sp}) {
    width: 100%;
    height: auto;
  }
`

const heading = css`
  width: 100%;
  align-items: baseline;
  display: flex;
  font-size: ${fontSizes.midium};
  font-weight: bold;

  p {
    line-height: 40px;
    margin: 0;
    white-space: nowrap;
  }

  p:first-child {
    ruby {
      ruby-position: over;

      rt {
        font-family: 'a-otf-gothic-bbb-pr6n', sans-serif;
        font-size: ${fontSizes.tiny};
      }
    }
  }

  @media (max-width: ${breakPoints.sp}) {
    font-size: 20px;

    rt {
      display: none;
    }
  }
`

const hyphen = css`
  margin: 0 6px;
`

const artistList = css`
  width: 100%;
  list-style: none;
  margin-bottom: 64px;
  padding-left: 0;
  text-align: left;
  white-space: nowrap;

  li {
    font-size: ${fontSizes.normal};
    font-size: 20px;
    line-height: 40px;
  }

  @media (max-width: ${breakPoints.sp}) {
    margin-bottom: 32px;
  }
`

const artist = css`
  margin: 0;
  align-items: center;
  display: flex;

  dt {
    font-size: ${fontSizes.normal};
    font-weight: bold;
    display: inline;
    white-space: nowrap;
  }

  dd {
    display: inline;
    margin-left: 4px;
    line-height: 32px;
  }

  @media (max-width: ${breakPoints.sp}) {
    dt {
      font-size: 20px;
    }

    dd {
      font-size: 14px;
      line-height: 24px;
    }
  }
`

const trackList = css`
  width: 100%;
  list-style-type: decimal-leading-zero;
  text-align: left;
  margin-top: auto;
  margin-left: 34.5px;

  li {
    font-size: ${fontSizes.normal};
    line-height: 40px;
  }

  @media (max-width: ${breakPoints.sp}) {
    margin-left: 16px;

    li {
      line-height: 32px;
      font-size: ${fontSizes.small};
    }
  }
`

export default memo(Info)
