import React, { memo } from 'react'
import { faAmazon, faApple, faBandcamp, faSpotify } from '@fortawesome/free-brands-svg-icons'
import { faList, faMusic } from '@fortawesome/free-solid-svg-icons'
import { css } from 'linaria'
import Anchor from '../common/Anchor'
import Title from '../common/Title'
import Icon from '../common/Icon'
import Image from '../common/Image'
import { breakPoints, colors, fontSizes } from '../../constants'

const DlAndStreaing: React.FC = () => (
  <section className={container}>
    <div className={background}>
      <Title className={title} lineColor="limonene">
        DL & Streaming
      </Title>
      <div className={box}>
        <h2 className={boxTitle}>ダウンロード</h2>
        <ul className={platformList}>
          <li>
            <Anchor
              className={platformAnchor}
              href="https://kamomesano.bandcamp.com/album/sour-salty-realism"
              external
            >
              <Icon className={platformIcon} size="20px" icon={faBandcamp} />
              Bandcamp
            </Anchor>
            <small className={description}>(自分語りミニパンフレットPDF版付き)</small>
          </li>
          <li>
            <Anchor
              className={platformAnchor}
              href="https://itunes.apple.com/jp/album/1535893572?app=itunes"
              external
            >
              <Icon className={platformIcon} size="20px" icon={faApple} />
              iTunes
            </Anchor>
          </li>
          <li>
            <Anchor
              className={platformAnchor}
              href="https://www.amazon.co.jp/gp/product/B08L7MXCB9"
              external
            >
              <Icon className={platformIcon} size="20px" icon={faAmazon} />
              Amazon Music
            </Anchor>
          </li>
          <li>
            <Anchor
              className={platformAnchor}
              href="https://mora.jp/package/43000074/TCJPR0000693641/"
              external
            >
              <Icon className={platformIcon} size="20px" icon={faMusic} />
              mora
            </Anchor>
          </li>
          <li>
            <Anchor className={platformAnchor} href="https://linkco.re/dxXCGgzh" external>
              <Icon className={platformIcon} size="20px" icon={faList} />
              Others
            </Anchor>
          </li>
        </ul>
      </div>
      <div className={box}>
        <h2 className={boxTitle}>ストリーミング</h2>
        <ul className={platformList}>
          <li>
            <Anchor
              className={platformAnchor}
              href="https://open.spotify.com/album/73J4EnzQCABir8uiiI02h6"
              external
            >
              <Icon className={platformIcon} size="20px" icon={faSpotify} />
              Spotify
            </Anchor>
          </li>
          <li>
            <Anchor
              className={platformAnchor}
              href="https://music.apple.com/jp/album/1535893572"
              external
            >
              <Icon className={platformIcon} size="20px" icon={faApple} />
              Apple Music
            </Anchor>
          </li>
          <li>
            <Anchor
              className={platformAnchor}
              href="https://music.line.me/webapp/album/mb0000000001e0cf7d"
              external
            >
              <Image className={platformIcon} src="icons/line-music.png" alt="LINE MUSIC" />
              LINE MUSIC
            </Anchor>
          </li>
          <li>
            <Anchor className={platformAnchor} href="https://awa.fm/" external>
              <Icon className={platformIcon} size="20px" icon={faMusic} />
              AWA
            </Anchor>
          </li>
          <li>
            <Anchor className={platformAnchor} href="https://linkco.re/dxXCGgzh" external>
              <Icon className={platformIcon} size="20px" icon={faList} />
              Others
            </Anchor>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

const container = css`
  width: 100%;
`

const title = css`
  position: absolute;
  top: -40px;
  left: 64px;

  @media (max-width: ${breakPoints.tablet}) {
    top: -16px;
    left: -16px;
  }
`

const background = css`
  width: 80%;
  max-width: 1400px;
  background-color: ${colors.background2};
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
  padding: 80px 64px 64px;
  position: relative;

  @media (max-width: ${breakPoints.sp}) {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    flex-direction: column;
    padding: 64px 24px 32px;
  }
`

const box = css`
  width: 50%;
  margin-right: 32px;

  @media (max-width: ${breakPoints.sp}) {
    max-width: initial;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`

const boxTitle = css`
  font-size: ${fontSizes.midium};
  margin: 8px 0;

  @media (max-width: ${breakPoints.sp}) {
    font-size: 20px;
  }
`

const platformList = css`
  list-style: none;
  padding-left: 0;

  li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: ${fontSizes.normal};
    line-height: 48px;
  }

  @media (max-width: ${breakPoints.sp}) {
    li {
      font-size: 18px;
      line-height: 40px;
    }
  }
`

const platformIcon = css`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 12px;

  @media (max-width: ${breakPoints.sp}) {
    width: 20px;
    height: 20px;
  }
`

const platformAnchor = css`
  margin-right: 8px;
  white-space: nowrap;
`

const description = css`
  font-size: ${fontSizes.small};
  line-height: 24px;
`

export default memo(DlAndStreaing)
