import React from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { css } from 'linaria'
import { breakPoints } from '../../constants'

type Props = Omit<FontAwesomeIconProps, 'size'> & {
  size: string
}

const Icon: React.FC<Props> = ({ className, size, ...props }) => (
  <span className={className}>
    {/* @ts-expect-error HACK: linaria の動的にスタイルを変更するために CSS Variable を定義*/}
    <FontAwesomeIcon className={style} style={{ '--size': size }} {...props} />
  </span>
)

const style = css`
  height: var(--size);
  width: var(--size);
  align-items: center;
  display: inline-flex;
  justify-content: center;

  svg {
    height: inherit;
    width: inherit;
  }

  @media (max-width: ${breakPoints.sp}) {
    min-width: 20px;
    min-height: 20px;
  }
`

export default Icon
