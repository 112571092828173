import React, { memo } from 'react'
import { css, cx } from 'linaria'
import { breakPoints, colors, fontSizes } from '../../constants'

type Props = {
  children: React.ReactNode
  className?: string
  id?: string
  lineColor: Extract<keyof typeof colors, 'limonene' | 'brand'>
}

const Title = ({ children, className, id, lineColor }: Props) => (
  <h1 id={id} className={cx(style, className)} data-line-color={lineColor}>
    {children}
  </h1>
)

const style = css`
  box-sizing: border-box;
  display: inline-block;
  font-size: ${fontSizes.big};
  margin: 0;
  position: relative;
  white-space: nowrap;
  z-index: 0;

  &::before {
    width: 100%;
    height: 16px;
    content: '';
    position: absolute;
    bottom: 36px;
    left: 0;
    z-index: -1;
  }

  &[data-line-color='limonene'] {
    &::before {
      background-color: ${colors['limonene']};
    }
  }

  &[data-line-color='brand'] {
    &::before {
      background-color: ${colors['brand']};
    }
  }

  @media (min-width: ${breakPoints.sp}) and (max-width: ${breakPoints.tablet}) {
    font-size: 80px;
  }

  @media (max-width: ${breakPoints.sp}) {
    font-size: 48px;

    &::before {
      width: 100%;
      height: 12px;
      content: '';
      position: absolute;
      bottom: 16px;
      left: 0;
      z-index: -1;
    }
  }
`

export default memo(Title)
