import React, { memo } from 'react'
import { css } from 'linaria'
import Anchor from '../common/Anchor'
import Title from '../common/Title'
import { breakPoints, colors, fontSizes } from '../../constants'

const Cd: React.FC = () => (
  <section className={container}>
    <div className={background}>
      <Title id="cd" className={title} lineColor="brand">
        CD
      </Title>
      <div className={box}>
        <h2 className={boxTitle}>イベント</h2>
        <div className={marginBottom}>
          <Anchor
            href="https://online.m3net.jp/circle.php?s=%E6%A9%99035"
            className={anchor}
            external
          >
            <p className={pragraph}>M3 2020秋 Web イベント</p>
          </Anchor>
        </div>
        <div className={marginBottom}>
          <p className={pragraph}>
            <time className={time} dateTime="2020-10-24">
              2020.10.24 (土)
            </time>
            <span className={hyphen}>-</span>
            <time className={time} dateTime="2020-10-25">
              2020.10.25 (日)
            </time>
          </p>
        </div>
        <strong className={description}>
          東京流通センターにて開催されるリアルイベントの方には出展いたしません。
        </strong>
      </div>
      <div className={box}>
        <h2 className={boxTitle}>通販</h2>
        <ul className={shopList}>
          <li>
            <Anchor
              href="https://www.melonbooks.co.jp/detail/detail.php?product_id=739171"
              className={anchor}
              external
            >
              <p className={pragraph}>メロンブックス</p>
            </Anchor>
          </li>
          <li>
            <Anchor
              href="https://www.tanocstore.net/shopdetail/000000002588/"
              className={anchor}
              external
            >
              <p className={pragraph}>TANO*C STORE</p>
            </Anchor>
          </li>
          <li>
            <Anchor href="https://limonenes.booth.pm/items/2461614" className={anchor} external>
              <p className={pragraph}>BOOTH</p>
            </Anchor>
          </li>
        </ul>
      </div>
      <div className={box}>
        <h2 className={boxTitle}>限定特典</h2>
        <p className={pragraph}>自分語りミニパンフレット2（ツー）</p>
        <p className={description}>
          今回もサノが全曲それなりに語ります。CDケースに収まるのでなくす心配もご無用。
        </p>
      </div>
    </div>
  </section>
)

const container = css`
  width: 100%;
`

const title = css`
  position: absolute;
  top: -40px;
  left: 64px;

  @media (max-width: ${breakPoints.tablet}) {
    top: -16px;
    left: 32px;
  }
`

const background = css`
  width: 80%;
  max-width: 1400px;
  background-color: ${colors.background2};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 80px 64px 64px;
  position: relative;

  @media (max-width: ${breakPoints.sp}) {
    width: calc(100% - 32px);
    flex-direction: column;
    padding: 64px 24px 32px;
  }
`

const box = css`
  max-width: 35%;

  @media (max-width: ${breakPoints.sp}) {
    max-width: initial;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`

const boxTitle = css`
  font-size: ${fontSizes.midium};
  margin-top: 8px;

  @media (max-width: ${breakPoints.sp}) {
    font-size: 20px;
  }
`

const anchor = css`
  @media (max-width: ${breakPoints.sp}) {
    p {
      font-size: 18px;
    }
  }
`

const pragraph = css`
  display: flex;
  flex-wrap: wrap;
  font-size: ${fontSizes.normal};
  line-height: 28px;
  margin: 0;
  pointer-events: auto;

  @media (max-width: ${breakPoints.sp}) {
    font-size: ${fontSizes.small};
    line-height: 20px;
  }
`

const marginBottom = css`
  margin-bottom: 16px;
`

const time = css`
  white-space: nowrap;
`

const hyphen = css`
  margin: 0 8px;
`

const description = css`
  font-size: ${fontSizes.small};
  font-weight: normal;
  line-height: 24px;
`

const shopList = css`
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 16px;
  }
`

export default memo(Cd)
