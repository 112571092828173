import { css, cx } from 'linaria'
import React, { memo } from 'react'
import useLazyloadRef from 'use-lazyload-ref'
import { colors } from '../../constants'

type Props = {
  className?: string
  src: string
  title: string
}

const Player: React.FC<Props> = ({ className, src, title }) => {
  const [ref, hasLoaded] = useLazyloadRef()

  return (
    <iframe
      ref={ref}
      data-src={src}
      data-loaded={hasLoaded}
      width={480}
      height={270}
      className={cx(style, className)}
      title={title}
      frameBorder={0}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}

const style = css`
  &[data-loaded] {
    @keyframes loading {
      0% {
        background-position: -200px 0;
      }
      100% {
        background-position: calc(200px + 100%) 0;
      }
    }

    animation: loading 0.75s ease-in-out infinite;
    background-color: ${colors.background1};
    background-image: linear-gradient(90deg, #fff, #ddd, #fff);
    background-size: 200px 100%;
    background-repeat: no-repeat;
  }
`

export default memo(Player)
