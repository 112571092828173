import React, { memo, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ShareInfoQuery } from 'graphql-types'
import { css } from 'linaria'
import Anchor from '../common/Anchor'
import Image from '../common/Image'
import { breakPoints, colors } from '../../constants'

const query = graphql`
  query ShareInfo {
    site {
      siteMetadata {
        author
        siteUrl
        title
      }
    }
  }
`

const SocialSharePoper: React.FC = () => {
  const [show, setShow] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const { site } = useStaticQuery<ShareInfoQuery>(query)

  useEffect(() => {
    const handleScroll = () => {
      if (!scrolled) setScrolled(true)

      const { pageYOffset } = window
      if (pageYOffset > 100 && !show) setShow(true)
      if (pageYOffset < 100) setShow(false)
    }
    document.addEventListener('scroll', handleScroll, { passive: true })
    return () => document.removeEventListener('scroll', handleScroll)
  }, [show, scrolled])

  if (!scrolled) return null

  return (
    <nav className={nav} data-show={show}>
      <ul className={list}>
        <li>
          <Anchor
            className={anchor}
            href={`https://twitter.com/share?url=${site?.siteMetadata?.siteUrl}&text=${encodeURI(
              site?.siteMetadata?.title ?? ''
            )}&related=${site?.siteMetadata?.author}`}
            underline={false}
            external
          >
            <Image className={icon} src="icons/twitter.png" alt="Twitter" />
          </Anchor>
        </li>
        <li>
          <Anchor
            className={anchor}
            href={`https://www.facebook.com/sharer/sharer.php?u=${site?.siteMetadata?.siteUrl}`}
            underline={false}
            external
          >
            <Image className={icon} src="icons/facebook.png" alt="Facebook" />
          </Anchor>
        </li>
        <li>
          <Anchor
            className={anchor}
            href={`https://timeline.line.me/social-plugin/share?url=${site?.siteMetadata?.siteUrl}`}
            underline={false}
            external
          >
            <Image className={icon} src="icons/line.png" alt="Line" />
          </Anchor>
        </li>
      </ul>
    </nav>
  )
}

const nav = css`
  width: 300px;
  background-color: ${colors.background1};
  border-radius: 40px;
  box-shadow: 0 0 12px #00000010;
  box-sizing: border-box;
  padding: 0 40px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s;
  transform: translateY(100px);

  &[data-show] {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: ${breakPoints.sp}) {
    width: 50%;
    margin-left: auto;
    padding: 0 28px;
  }
`

const list = css`
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;

  li {
    width: 40px;
    height: 40px;
  }

  @media (max-width: ${breakPoints.sp}) {
    margin: 8px 0;

    li {
      width: 32px;
      height: 32px;
    }
  }
`

const anchor = css`
  width: 100%;
  height: 100%;
`

const icon = css`
  width: 100%;
  height: 100%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default memo(SocialSharePoper)
