import React, { memo, ReactNode } from 'react'
import { css, cx } from 'linaria'
import { colors } from '../../constants'

type Props = {
  className?: string
  children: ReactNode
  external?: boolean
  href?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  underline?: boolean
}

const Anchor: React.FC<Props> = ({
  className,
  children,
  external = false,
  underline = true,
  ...props
}) => (
  <a
    className={cx(style, className)}
    target={external ? '_blank' : undefined}
    rel={external ? 'noopener noreferrer' : undefined}
    data-underline={underline}
    {...props}
  >
    {children}
  </a>
)

const style = css`
  color: ${colors.text1};
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: all 0.4s ease;

  &[data-underline='true'] {
    &::after {
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: calc(100% / 3);
      left: 0;
      content: '';
      background-color: ${colors.text2};
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease;
    }

    &:hover {
      &::after {
        bottom: calc(100% / 5);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:hover {
    color: ${colors.text2};
  }

  &:not([href]) {
    color: ${colors.text3};
    cursor: default;
    pointer-events: none;

    &::after {
      content: initial;
    }
  }
`

export default memo(Anchor)
