import React from 'react'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { css } from 'linaria'
import Anchor from '../components/common/Anchor'
import Head from '../components/common/Head'
import Icon from '../components/common/Icon'
import Image from '../components/common/Image'
import SocialSharePoper from '../components/parts/SocialSharePoper'
import Hero from '../components/sections/Hero'
import Info from '../components/sections/Info'
import Cd from '../components/sections/Cd'
import DlAndStreaming from '../components/sections/DlAndStreaming'
import Member from '../components/sections/Member'
import { breakPoints, colors, fontSizes } from '../constants'

const Page: React.FC = () => (
  <>
    <Head />
    <main className={main}>
      <SocialSharePoper />
      <Hero />
      <Info />
      <Cd />
      <DlAndStreaming />
      <Member />
    </main>
    <nav className={nav}>
      <Anchor className={twitterLink} href="https://twitter.com/limoneneinfo" external>
        <Icon icon={faTwitter} />
        @limoneneinfo
      </Anchor>
      <section className={flexbox}>
        <Anchor href="https://limonene.link" underline={false} external>
          <figure className={figure}>
            <Image
              className={artwork}
              src="artworks/bitter-sweet-idealism.png"
              alt="Bitter Sweet Idealism"
            />
            <figcaption className={caption}>
              Bitter Sweet Idealism
              <Icon className={externalIcon} icon={faExternalLinkAlt} />
            </figcaption>
          </figure>
        </Anchor>
        <Anchor href="https://limonene.link/evergreen/" underline={false} external>
          <figure className={figure}>
            <Image className={artwork} src="artworks/evergreen.png" alt="Evergreen" />
            <figcaption className={caption}>
              Evergreen
              <Icon className={externalIcon} icon={faExternalLinkAlt} />
            </figcaption>
          </figure>
        </Anchor>
      </section>
    </nav>
    <footer className={footer}>
      <small>&copy; Limonène</small>
    </footer>
  </>
)

const main = css`
  width: 100%;

  > section {
    &:not(:last-child) {
      margin-bottom: 10vh;
    }

    @media (min-width: ${breakPoints.sp}) {
      margin-bottom: 10vh;
    }
  }
`

const nav = css`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10vh;
`

const twitterLink = css`
  font-size: ${fontSizes.large};
  font-weight: bold;
  margin: 5vh 0;

  @media (max-width: ${breakPoints.sp}) {
    font-size: ${fontSizes.midium};
  }
`

const flexbox = css`
  display: flex;
  justify-content: center;
`

const figure = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.15s;

  &:hover {
    filter: brightness(0.95);
  }

  @media (max-width: ${breakPoints.sp}) {
    width: 100px;
  }
`

const caption = css`
  font-size: ${fontSizes.normal};

  @media (max-width: ${breakPoints.sp}) {
    white-space: nowrap;
    font-size: ${fontSizes.small};
  }
`

const artwork = css`
  width: 20vw;
  height: auto;
  margin-bottom: 16px;

  @media (max-width: ${breakPoints.sp}) {
    width: 38vw;
  }
`

const externalIcon = css`
  margin-left: 8px;
`

const footer = css`
  width: 100%;
  height: 100px;
  border-top: 8px solid ${colors['brand']};
  display: grid;
  place-items: center;

  small {
    font-size: ${fontSizes.small};
  }

  @media (max-width: ${breakPoints.sp}) {
    height: 64px;
  }
`

export default Page
