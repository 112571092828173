import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { ImagesQuery } from 'graphql-types'
import { css, cx } from 'linaria'

type Props = {
  alt: string
  className?: string
  src: string
}

const query = graphql`
  query Images {
    allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Image: React.FC<Props> = ({ alt, className, src }) => {
  const { allFile } = useStaticQuery<ImagesQuery>(query)
  const image = allFile.edges.find(({ node }) => node.relativePath === src)

  if (!image?.node.childImageSharp) return null

  return (
    <Img
      className={cx(style, className)}
      fluid={image.node.childImageSharp.fluid as FluidObject}
      alt={alt}
    />
  )
}

const style = css`
  width: 100px;
  height: 100px;
`

export default memo(Image)
