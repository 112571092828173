import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { SiteQuery } from 'graphql-types'

const query = graphql`
  query Site {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`

const Head: React.FC = () => {
  const { site } = useStaticQuery<SiteQuery>(query)

  const contentMeta: JSX.IntrinsicElements['meta'][] = [
    {
      name: 'description',
      content: site?.siteMetadata?.description ?? '',
    },
    {
      property: 'og:title',
      content: site?.siteMetadata?.title ?? '',
    },
    {
      property: 'og:description',
      content: site?.siteMetadata?.description ?? '',
    },
    {
      property: 'og:site_name',
      content: site?.siteMetadata?.title ?? '',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: site?.siteMetadata?.siteUrl ?? '',
    },
    {
      property: 'og:image',
      content: `${site?.siteMetadata?.siteUrl}/ogp.jpg`,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:site',
      content: site?.siteMetadata?.author || '',
    },
    {
      name: 'twitter:title',
      content: site?.siteMetadata?.title ?? '',
    },
    {
      name: 'twitter:description',
      content: site?.siteMetadata?.description ?? '',
    },
  ]

  return (
    <Helmet htmlAttributes={{ lang: 'ja' }} meta={contentMeta}>
      <title>{site?.siteMetadata?.title}</title>
    </Helmet>
  )
}

export default Head
